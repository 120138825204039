import axios from "axios";
export default {
  namespaced: true,
  state: {
    quotations: [],
  },
  getters: {
    quotations(state) {
      return state.quotations;
    },
  },
  mutations: {
    SET_QUOTATIONS(state, quotations) {
      state.quotations = quotations;
    },
    ADD_QUOTATION(state, quotation) {
      state.quotations.unshift(quotation);
    },
    EDIT_QUOTATION(state, quotation) {
      var index = state.quotations.findIndex((a) => a.id == quotation.id);
      state.quotations.splice(index, 1, quotation);
    },
    DELETE_QUOTATION(state, quotation) {
      let filtered = state.quotations.filter((c) => {
        return c.id != quotation.id;
      });
      state.quotations = filtered;
    },
  },
  actions: {
    // get quotations
    async get_quotations({ commit }) {
      let response = await axios.get("/quotations");
      commit("SET_QUOTATIONS", response.data);
    },
    async add_quotation({ commit }, request) {
      let response = await axios.post("/quotation", request);
      commit("ADD_QUOTATION", response.data);
    },
    async edit_quotation({ commit }, request) {
      let response = await axios.put("/quotation", request);
      commit("EDIT_QUOTATION", response.data);
    },
    async delete_quotation({ commit }, request) {
      await axios.delete("/quotation/" + request.id);
      commit("DELETE_QUOTATION", request);
    },
  },
};
