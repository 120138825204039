<template>
  <div>
    <h4>{{ label }}</h4>
    <input type="date" v-model="date" />
    <input type="time" v-model="time" class="ml-2 mr-2" v-if="enableTime" />
    <v-btn small :color="color" @click="update()" class="ml-2 mr-6">Set</v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["label", "enableTime"],
  data() {
    return {
      date: "",
      time: "",
      color: "yellow",
    };
  },

  computed: {},
  created() {},
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),
    update() {
      if (
        this.date === "" ||
        (this.disableTime === false && this.time === "")
      ) {
        return alert("Please check all fields");
      }

      let dateTime = this.disableTime ? this.date : this.date + " " + this.time;

      this.$emit("setDateTime", dateTime);

      const notification = {
        message: "Date successfully set.",
        color: "success",
        showing: true,
        icon: "mdi-check",
      };

      this.set_notification(notification);
    },
  },
};
</script>

<style></style>
