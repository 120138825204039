<template>
  <div>
    <v-btn @click="dialog = true" text color="teal" small
      ><v-icon class="mr-1">mdi-truck-flatbed</v-icon>Item Orders</v-btn
    >
    <v-dialog v-model="dialog" persistent width="1500">
      <v-card>
        <v-card-title
          >Item Orders
          <v-spacer></v-spacer>
          <add-item-orders-modal />
        </v-card-title>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            v-model="search"
            prepend-icon="mdi-magnify"
          ></v-text-field>
          <v-data-table
            :items="item_orders"
            :headers="headers"
            :search="search"
          >
            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <edit-item-orders-modal :item="item" />
                <update-item-orders-modal :item="item" />
                <v-btn icon color="red" @click="del_item(item)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
            <template v-slot:item.order_status="{ item }">
              <v-chip
                v-if="item.order_status == 'pending'"
                class="white--text"
                color="orange"
              >
                Pending
              </v-chip>
              <v-chip
                v-else-if="item.order_status == 'returned'"
                class="white--text"
                color="red"
              >
                Returned
              </v-chip>
              <v-chip v-else color="success"> Delivered </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">itemOrdersModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import addItemOrdersModal from "./addItemOrdersModal.vue";
import EditItemOrdersModal from "./editItemOrdersModal.vue";
import UpdateItemOrdersModal from "./updateItemOrdersModal.vue";
export default {
  components: {
    addItemOrdersModal,
    EditItemOrdersModal,
    UpdateItemOrdersModal,
  },
  data() {
    return {
      search: "",
      dialog: false,
      loading: false,
      headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Customer Name", value: "customer_name" },
        { text: "Item Type", value: "item_type" },
        { text: "Item Name", value: "item_name" },
        { text: "Technician", value: "technician" },
        { text: "Unit Price", value: "unit_price" },
        { text: "Selling Price", value: "selling_price" },
        { text: "Supplier", value: "supplier" },
        { text: "Date Ordered", value: "date_ordered" },
        { text: "Remarks", value: "remarks" },
        { text: "Order Status", value: "order_status" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      item_orders: "item/item_orders",
      customers: "customer/customers",
      users: "auth/users",
      suppliers: "supplier/suppliers",
    }),
  },
  methods: {
    ...mapActions({
      get_users: "auth/get_users",
      get_customers: "customer/get_customers",
      get_suppliers: "supplier/get_suppliers",
      get_item_orders: "item/get_item_orders",
      delete_item_order: "item/delete_item_order",
    }),

    del_item(item) {
      let x = window.confirm("Are you sure you want to delete this order?");
      if (x) {
        this.delete_item_order(item.id).then(() => {
          alert("Successfully deleted an order");
        });
      }
    },
  },
  created() {
    if (this.item_orders.length <= 0) this.get_item_orders();
    if (this.users.length <= 0) this.get_users();
    if (this.customers.length <= 0) this.get_customers();
    if (this.suppliers.length <= 0) this.get_suppliers();
  },
};
</script>

<style lang="scss" scoped></style>
