<template>
  <div>
    <v-btn @click="dialog = true" icon color="yellow"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Update Returned Item</v-card-title>
        <v-card-text>
          <v-text-field
            label="Item Name"
            v-model="item.item.item_name"
          ></v-text-field>
          <v-text-field
            label="S/N"
            v-model="item.product_serial_number"
          ></v-text-field>
          <v-textarea
            label="Problem Description"
            v-model="item.problem_description"
          ></v-textarea>
          <div class="d-flex">
            <custom-date-picker
              label="Date Returned"
              :enableTime="false"
              @setDateTime="date_returned"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import customDatePicker from "../customDatePicker.vue";
export default {
  components: { customDatePicker },
  props: ["item"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      edit_returned_item: "item/edit_returned_item",
    }),
    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to edit this entry?");
      if (x) {
        this.edit_returned_item(this.item).then(() => {
          alert("Successfully edited an item");
        });
      }
      this.loading = false;
    },
    date_returned(data) {
      this.item.date_returned = data;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
