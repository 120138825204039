import axios from "axios";
export default {
  namespaced: true,
  state: {
    suppliers: [],
  },
  getters: {
    suppliers(state) {
      return state.suppliers;
    },
  },
  mutations: {
    SET_SUPPLIERS(state, suppliers) {
      state.suppliers = suppliers;
    },
    ADD_SUPPLIER(state, supplier) {
      state.suppliers.unshift(supplier);
    },
    EDIT_SUPPLIER(state, supplier) {
      var index = state.suppliers.findIndex((a) => a.id == supplier.id);
      state.suppliers.splice(index, 1, supplier);
    },
    DELETE_SUPPLIER(state, supplier_id) {
      let filtered = state.suppliers.filter((c) => {
        return c.id != supplier_id;
      });
      state.suppliers = filtered;
    },
  },
  actions: {
    // get suppliers
    async get_suppliers({ commit }) {
      let response = await axios.get("/suppliers");
      commit("SET_SUPPLIERS", response.data);
    },
    async add_supplier({ commit }, request) {
      let response = await axios.post("/supplier", request);
      commit("ADD_SUPPLIER", response.data);
    },
    async edit_supplier({ commit }, request) {
      let response = await axios.put("/supplier", request);
      commit("EDIT_SUPPLIER", response.data);
    },
    async delete_supplier({ commit }, supplier_id) {
      await axios.delete("/supplier/" + supplier_id);
      commit("DELETE_SUPPLIER", supplier_id);
    },
  },
};
