<template>
  <div>
    <v-btn @click="dialog = true" text small color="red darken-3">
      <v-badge color="red" :content="pending_units.length" v-if="pending_units">
        <v-icon class="mr-1">mdi-laptop-account</v-icon>Pending Units</v-badge
      >
      <span v-else> Pending Units</span>
    </v-btn>

    <v-dialog v-model="dialog" persistent width="1250" v-if="pending_units">
      <v-card>
        <v-card-title>Pending Units</v-card-title>
        <v-card-text class="container">
          <div v-if="ready">
            <v-row>
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
              ></v-text-field>
              <v-btn
                text
                color="green"
                small
                :loading="loading"
                @click="refresh"
                ><v-icon class="mr-2">mdi-refresh</v-icon>Refresh Table</v-btn
              >
            </v-row>

            <v-data-table
              :headers="headers"
              :items="pending_units"
              :search="search"
            >
              <template v-slot:item.action="{ item }">
                <v-btn
                  @click="show_problem(item)"
                  color="primary"
                  small
                  icon
                  class="mt-1"
                  ><v-icon>mdi-list-box</v-icon></v-btn
                >
              </template>
              <template v-slot:item.status="{ item }">
                <p class="orange--text darken-4">{{ item.status }}</p>
              </template>
            </v-data-table>
            <problem-layout-modal
              class="hidden-md-and-down"
              :problem="selected_problem"
              v-if="show_problem_layout"
              @close="show_problem_layout = false"
            />
            <problem-layout-modal-mobile
              class="hidden-md-and-up"
              :problem="selected_problem"
              v-if="show_problem_layout"
              @close="show_problem_layout = false"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">pendingUnits.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProblemLayoutModal: () => import("../Problems/problemLayoutModal.vue"),
    ProblemLayoutModalMobile: () =>
      import("../Problems/problemLayoutModalMobile.vue"),
  },
  data() {
    return {
      ready: false,
      search: "",
      selected_problem: [],
      show_problem_layout: false,
      headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Customer Name", value: "unit.customer.customer_name" },
        { text: "Problem Description", value: "problem_description" },
        { text: "Unit Brand", value: "unit.unit_brand" },
        { text: "Unit Model", value: "unit.unit_model" },
        { text: "Type", value: "unit.unit_type" },
        { text: "Date Inserted", value: "created_at" },
        { text: "Last Update", value: "updated_at" },
        { text: "Status", value: "status" },
      ],
      dialog: false,
      loading: false,
      problem_dialog: false,
    };
  },
  methods: {
    ...mapActions({
      get_unit_status: "unit/get_unit_status",
      set_problem: "problem/set_problem",
      get_pending_units: "unit/get_pending_units",
    }),
    show_problem(item) {
      this.selected_problem = item;
      this.show_problem_layout = true;
    },
    open_problem_dialog(item) {
      this.selected_item = item;
      this.set_problem(item).then(() => {
        this.problem_dialog = true;
        console.log(this.selected_item.id);
      });
    },
    refresh() {
      this.loading = true;
      this.get_pending_units().then(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      problem: "problem/problem",
      pending_units: "unit/pending_units",
    }),
  },
  created() {
    if (this.pending_units.length <= 0) {
      this.get_pending_units();
    }
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped></style>
