import axios from "axios";
export default {
  namespaced: true,
  state: {
    branches: [],
    branch: null,
  },
  getters: {
    branches(state) {
      return state.branches;
    },
    branch(state) {
      return state.branch;
    },
  },
  mutations: {
    SET_branch(state, branch) {
      state.branch = branch;
    },
    SET_branches(state, branches) {
      state.branches = branches;
    },
    ADD_branch(state, branch) {
      state.branches.unshift(branch);
    },
    EDIT_branch(state, branch) {
      var index = state.branches.findIndex((a) => a.id == branch.id);
      state.branches.splice(index, 1, branch);
    },
    DELETE_branch(state, branch) {
      let filtered = state.branches.filter((c) => {
        return c.id != branch.id;
      });
      state.branches = filtered;
    },
    branch_SIGNOUT(state) {
      state.branch = "";
    },
  },
  actions: {
    async branch_signout({ commit }) {
      commit("branch_SIGNOUT");
    },
    async get_branch({ commit }, branch_code) {
      let response = await axios.get("/branch_info/" + branch_code);

      commit("SET_branch", response.data);
    },
    // get branches
    async get_branches({ commit }) {
      let response = await axios.get("/branches");
      commit("SET_branches", response.data);
    },
    async add_branch({ commit }, request) {
      let response = await axios.post("/branch", request);
      commit("ADD_branch", response.data);
    },
    async edit_branch({ commit }, request) {
      let response = await axios.put("/branch", request);
      commit("EDIT_branch", response.data);
    },
    async delete_branch({ commit }, request) {
      await axios.delete("/branch/" + request.id);
      commit("DELETE_branch", request);
    },
  },
};
