import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.js";
import notification from "./notification.js";
import unit from "./unit.js";
import customer from "./customer.js";
import problem from "./problem.js";
import payment from "./payment.js";
import service from "./service.js";
import item from "./item.js";
import invoice from "./invoice.js";
import supplier from "./supplier.js";
import summary from "./summary.js";
import expense from "./expense.js";
import reminder from "./reminder.js";
import employee from "./employee.js";
import importBatch from "./importBatch.js";
import wage from "./wage.js";
import branch from "./branch.js";
import chart_of_account from "./chart_of_account.js";
import journal_entry from "./journal_entry.js";
import quotation from "./quotation.js";
import item_type from "./item_type.js";
import item_transfer from "./itemTransfer.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    item_type,
    supplier,
    summary,
    auth,
    notification,
    unit,
    customer,
    problem,
    payment,
    service,
    item,
    invoice,
    expense,
    reminder,
    employee,
    importBatch,
    wage,
    chart_of_account,
    journal_entry,
    branch,
    quotation,
    item_transfer,
  },
});
