import axios from "axios";
import store from "@/store";
export default {
  namespaced: true,
  state: {
    payments: null,
  },
  getters: {
    payments(state) {
      return state.payments;
    },
  },
  mutations: {
    SET_PAYMENTS(state, payments) {
      state.payments = payments;
    },
    ADD_PAYMENT(state, payment) {
      state.payments.unshift(payment);
    },
    EDIT_PAYMENT(state, payment) {
      var index = state.payments.findIndex((a) => a.id == payment.id);
      state.payments.splice(index, 1, payment);
    },
  },
  actions: {
    async get_payments({ commit }, customer_id) {
      let response = await axios.get("/payments/" + customer_id);
      commit("SET_PAYMENTS", response.data);
    },
    async add_payment(_, request) {
      let response = await axios.post("/payment", request);

      store.dispatch("invoice/edit_invoice", response.data, { root: true });
    },
    async edit_payment(_, payment) {
      await axios.put("/payment", payment);
    },
  },
};
