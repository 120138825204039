import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
const Dashboard = () => import("../views/Dashboard.vue");
const Items = () => import("../views/Items.vue");
const Signin = () => import("../views/Signin.vue");
const Accounts = () => import("../views/Accounts.vue");
const CustomerDashboard = () => import("../views/CustomerDashboard.vue");
const BatchImport = () => import("../views/BatchImport.vue");
const Expense = () => import("../views/Expense.vue");
const Suppliers = () => import("../views/Suppliers.vue");
const Employees = () => import("../views/Employees.vue");
const UserAccounts = () => import("../views/UserAccounts.vue");
const ChartOfAccounts = () => import("../views/ChartOfAccounts.vue");
const JournalEntry = () => import("../views/JournalEntry.vue");
const AccountProfile = () => import("../views/AccountProfile.vue");
const Invoices = () => import("../views/Invoices.vue");
const Units = () => import("../views/Units.vue");
const Bills = () => import("../views/Bills.vue");
const Branches = () => import("../views/Branches.vue");
const Quotations = () => import("../views/Quotations.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) return next({ name: "Signin" });
      // if (store.getters["auth/user"].role == "technician")
      // return next({ name: "Customer" });

      next();
    },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"] && to.name !== "Signin") {
        return next({ name: "Dashboard" });
      }
      next();
    },
  },
  {
    path: "/branches",
    name: "Branches",
    component: Branches,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      }
      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated");
      }
      next();
    },
  },
  {
    path: "/user_accounts",
    name: "User Accounts",
    component: UserAccounts,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      }
      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated");
      }
      next();
    },
  },
  {
    path: "/account_profile/:account_id",
    name: "Account Profile",
    component: AccountProfile,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      }
      // if the user is admin
      if (store.getters["auth/user"].access_level < 3) {
        return alert("Unauthenticated");
      }
      next();
    },
  },
  {
    path: "/units",
    name: "Units",
    component: Units,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      }
      // if the user is admin
      if (store.getters["auth/user"].access_level < 3) {
        return alert("Unauthenticated");
      }
      next();
    },
  },
  {
    path: "/bills",
    name: "Bills",
    component: Bills,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      }
      // if the user is admin
      if (store.getters["auth/user"].access_level < 3) {
        return alert("Unauthenticated");
      }
      next();
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      }
      // if the user is not admin or staff
      if (store.getters["auth/user"].access_level < 3) {
        return alert("Unauthenticated");
      }
      next();
    },
  },
  {
    path: "/quotations",
    name: "Quotations",
    component: Quotations,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "Signin" });
      }
      // if the user is not admin or staff
      if (store.getters["auth/user"].access_level < 3) {
        return alert("Unauthenticated");
      }
      next();
    },
  },
  {
    path: "/customerDash",
    name: "Customer Dashboard",
    component: CustomerDashboard,
    beforeEnter: (to, from, next) => {
      if (!store.getters["customer/customer"]) {
        alert("You are not logged in!");
        return next({ name: "Signin" });
      }
      // if the user is not staff or admin
      if (store.getters["auth/user"].access_level < 3) {
        alert("Unauthenticated access");
      }
      next();
    },
  },
  {
    path: "/items",
    name: "Item",
    component: Items,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }

      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated access");
      }
      next();
    },
  },
  {
    path: "/batch_import",
    name: "Batch Import",
    component: BatchImport,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }
      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated access");
      }

      next();
    },
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: Expense,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }
      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated access");
      }

      next();
    },
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: Suppliers,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }

      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated access");
      }

      next();
    },
  },
  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }
      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated access");
      }

      next();
    },
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }
      // if the user is
      if (store.getters["auth/user"].access_level < 3) {
        return alert("Unauthenticated access");
      }
      next();
    },
  },
  {
    path: "/chart_of_accounts",
    name: "Chart Of Accounts",
    component: ChartOfAccounts,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }

      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated access");
      }
      next();
    },
  },
  {
    path: "/journal_entries",
    name: "Journal Entries",
    component: JournalEntry,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        alert("Unauthenticated Access");
        return next({ name: "Signin" });
      }

      // if the user is not admin
      if (store.getters["auth/user"].access_level < 4) {
        return alert("Unauthenticated access");
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
