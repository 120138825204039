import axios from "axios";
export default {
  namespaced: true,
  state: {
    wages: [],
  },
  getters: {
    wages(state) {
      return state.wages;
    },
  },
  mutations: {
    SET_WAGES(state, wages) {
      state.wages = wages;
    },
    ADD_WAGE(state, wage) {
      state.wages.unshift(wage);
    },
    EDIT_WAGE(state, wage) {
      var index = state.wages.findIndex((a) => a.id == wage.id);
      state.wages.splice(index, 1, wage);
    },
  },
  actions: {
    async get_wages({ commit }) {
      let response = await axios.get("/wages");
      commit("SET_WAGES", response.data);
    },
    async add_wage({ commit }, request) {
      let response = await axios.post("/wage", request);
      commit("ADD_WAGE", response.data);
    },
    async edit_wage({ commit }, request) {
      let response = await axios.put("/wage", request);
      commit("EDIT_WAGE", response.data);
    },
  },
};
