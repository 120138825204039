import axios from "axios";
export default {
  namespaced: true,
  state: {
    pending_tasks: [],
    finished_tasks: [],
  },
  getters: {
    finished_tasks(state) {
      return state.finished_tasks;
    },
    pending_tasks(state) {
      return state.pending_tasks;
    },
    reminders(state) {
      return state.reminders;
    },
    reminder(state) {
      return state.reminder;
    },
  },
  mutations: {
    SET_PENDING_TASKS(state, pending_tasks) {
      state.pending_tasks = pending_tasks;
    },
    SET_FINISHED_TASKS(state, finished_tasks) {
      state.finished_tasks = finished_tasks;
    },
    SET_REMINDER(state, reminder) {
      state.reminder = reminder;
    },
    SET_REMINDERS(state, reminders) {
      state.reminders = reminders;
    },
    ADD_REMINDER(state, task) {
      state.pending_tasks.unshift(task);
    },
    EDIT_REMINDER(state, task) {
      var index = state.pending_tasks.findIndex((a) => a.id == task.id);
      state.finished_tasks.splice(index, 1, task);
    },
    DELETE_REMINDER(state, reminder) {
      let filtered = state.pending_tasks.filter((c) => {
        return c.id != reminder.id;
      });
      state.pending_tasks = filtered;
    },
    SET_DAILY_REMINDERS(state, daily_reminders) {
      state.daily_reminders = daily_reminders;
    },
  },
  actions: {
    async reminder_signout({ commit }) {
      commit("REMINDER_SIGNOUT");
    },
    async get_reminder({ commit }, reminder_code) {
      let response = await axios.get("/reminder_info/" + reminder_code);
      commit("SET_REMINDER", response.data);
    },
    // get reminders
    async get_reminders({ commit }, is_admin) {
      let finished_tasks = await axios.get("/tasks/" + is_admin + "/finished");
      let pending_tasks = await axios.get("/tasks/" + is_admin + "/pending");
      commit("SET_PENDING_TASKS", pending_tasks.data);
      commit("SET_FINISHED_TASKS", finished_tasks.data);
    },
    async get_daily_reminders({ commit }, request) {
      let is_admin = request.is_admin;
      let date = request.date;
      let response = await axios.get(
        "get_daily_reminders/" + is_admin + "/" + date
      );
      commit("SET_DAILY_REMINDERS", response.data);
    },
    async add_reminder({ commit }, request) {
      let response = await axios.post("/reminder", request);
      commit("ADD_REMINDER", response.data);
    },
    async edit_reminder({ commit }, request) {
      let response = await axios.put("/reminder", request);
      commit("EDIT_REMINDER", response.data);
    },
    async delete_reminder({ commit }, request) {
      await axios.delete("/reminder/" + request.id);
      commit("DELETE_REMINDER", request);
    },
  },
};
