import axios from "axios";
export default {
  namespaced: true,
  state: {
    customers: [],
    customer: null,
  },
  getters: {
    customers(state) {
      return state.customers;
    },
    customer(state) {
      return state.customer;
    },
  },
  mutations: {
    SET_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    SET_CUSTOMERS(state, customers) {
      state.customers = customers;
    },
    ADD_CUSTOMER(state, customer) {
      state.customers.unshift(customer);
    },
    EDIT_CUSTOMER(state, customer) {
      var index = state.customers.findIndex((a) => a.id == customer.id);
      state.customers.splice(index, 1, customer);
    },
    DELETE_CUSTOMER(state, customer) {
      let filtered = state.customers.filter((c) => {
        return c.id != customer.id;
      });
      state.customers = filtered;
    },
    CUSTOMER_SIGNOUT(state) {
      state.customer = "";
    },
  },
  actions: {
    async customer_signout({ commit }) {
      commit("CUSTOMER_SIGNOUT");
    },
    async get_customer({ commit }, customer_code) {
      let response = await axios.get("/customer_info/" + customer_code);

      commit("SET_CUSTOMER", response.data);
    },
    // get customers
    async get_customers({ commit }) {
      let response = await axios.get("/customers");
      commit("SET_CUSTOMERS", response.data);
    },
    async add_customer({ commit }, request) {
      let response = await axios.post("/customer", request);
      commit("ADD_CUSTOMER", response.data);
    },
    async edit_customer({ commit }, request) {
      let response = await axios.put("/customer", request);
      commit("EDIT_CUSTOMER", response.data);
    },
    async delete_customer({ commit }, request) {
      await axios.delete("/customer/" + request.id);
      commit("DELETE_CUSTOMER", request);
    },
  },
};
