import axios from "axios";
export default {
  namespaced: true,
  state: {
    item_transfers: [],
  },
  getters: {
    item_transfers(state) {
      return state.item_transfers;
    },
  },
  mutations: {
    SET_ITEM_TRANSFERS(state, item_transfers) {
      state.item_transfers = item_transfers;
    },
    ADD_ITEM_TRANSFER(state, item_transfer) {
      state.item_transfers.unshift(item_transfer);
    },

    EDIT_ITEM_TRANSFER(state, item_transfer) {
      var index = state.item_transfers.findIndex(
        (a) => a.id == item_transfer.id
      );
      state.item_transfers.splice(index, 1, item_transfer);
    },
    DELETE_ITEM_TRANSFER(state, request) {
      var filtered;

      filtered = state.item_transfers.filter((i) => {
        return i.id != request.id;
      });
      state.item_transfers = filtered;
    },
  },
  actions: {
    async get_item_transfers({ commit }) {
      let response = await axios.get("/item_transfers");
      commit("SET_ITEM_TRANSFERS", response.data);
    },
    async add_item_transfer({ commit }, request) {
      let response = await axios
        .post("/item_transfer", request)
        .catch((err) => {
          alert(err.response.data.error);
        });
      commit("ADD_ITEM_TRANSFER", response.data);
      return response;
    },
    async edit_item_transfer({ commit }, request) {
      let response = await axios.put("/item_transfer", request);
      commit("EDIT_ITEM_TRANSFER", response.data);
    },
    async delete_item_transfer({ commit }, request) {
      var id = request.id;
      await axios.delete("/item_transfer/" + id).catch((e) => alert(e));
      commit("DELETE_ITEM_TRANSFER", request);
    },
  },
};
