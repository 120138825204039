<template>
  <div>
    <v-btn color="orange" class="" icon small @click="dialog = true"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Edit Item Order</v-card-title>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            v-model="search"
          ></v-text-field>
          <v-data-table :items="customers" :headers="customer_headers">
            <template v-slot:item.action="{ item }">
              <v-btn x-small @click="select(item)">Select</v-btn>
            </template>
          </v-data-table>
          <v-text-field
            placeholder="Customers Name"
            prepend-icon="mdi-account-circle"
            v-model="item.customer_name"
            readonly
          ></v-text-field>

          <v-select
            :items="item_types"
            placeholder="Item Type"
            prepend-icon="mdi-printer"
            v-model="item.item_type"
          ></v-select>
          <v-text-field
            placeholder="Item Name"
            v-model="item.item_name"
            prepend-icon="mdi-pen"
          ></v-text-field>
          <v-select
            :items="users"
            item-text="full_name"
            prepend-icon="mdi-account-wrench"
            placeholder="Technician"
            v-model="item.technician"
          ></v-select>
          <v-text-field
            placeholder="Unit Price"
            v-model="item.unit_price"
            type="number"
            prepend-icon="mdi-cash"
          ></v-text-field>

          <v-text-field
            placeholder="Selling Price"
            v-model="item.selling_price"
            type="number"
            prepend-icon="mdi-cash-multiple"
          ></v-text-field>
          <v-select
            placeholder="Supplier"
            :items="suppliers"
            item-text="supplier_name"
            v-model="item.supplier"
            prepend-icon="mdi-account-outline"
          ></v-select>
          <v-text-field
            placeholder="Remarks"
            prepend-icon="mdi-pen"
            v-model="item.remarks"
          ></v-text-field>
          <v-row>
            <v-col>
              <p>Date Ordered</p>
              <input
                type="date"
                placeholder="Date Ordered"
                v-model="item.date_ordered"
            /></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      selected_customer_name: "",
      search: "",
      dialog: false,
      loading: false,
      form: {
        customer_id: "",
        item_type: "",
        item_name: "",
        technician: "",
        unit_price: "",
        selling_price: "",
        supplier: "",
        date_ordered: "",
        remarks: "",
      },
      item_types: ["Printer", "Laptop", "Desktop", "CCTV", "Others"],
      customer_headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Customer Name", value: "customer_name" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customers: "customer/customers",
      users: "auth/users",
      suppliers: "supplier/suppliers",
    }),
  },
  methods: {
    some_fields_are_empty() {
      return Object.values(this.item).some((value) => value === "");
    },
    select(item) {
      this.form.customer_name = item.customer_name;
    },
    submit() {
      this.loading = true;
      if (this.some_fields_are_empty()) {
        alert("Please check all fields");
        console.log(this.item);
        this.loading = false;
        return;
      }
      let x = window.confirm("Are you sure you want to edit this order?");
      if (x) {
        this.edit_item_order(this.item).then(() => {
          alert("Successfully edited an order");
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    ...mapActions({
      edit_item_order: "item/edit_item_order",
    }),
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
