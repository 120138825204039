import axios from "axios";
export default {
  namespaced: true,
  state: {
    summary: null,
    sales_summary: [],
    yearly_summary: null,
    picked_up_units: [],
    unpaid_invoices_summary: [],
  },
  getters: {
    unpaid_invoices_summary(state) {
      return state.unpaid_invoices_summary;
    },
    sales_summary(state) {
      return state.sales_summary;
    },
    summary(state) {
      return state.summary;
    },
    yearly_summary(state) {
      return state.yearly_summary;
    },
    picked_up_units(state) {
      return state.picked_up_units;
    },
  },
  mutations: {
    SET_UNPAID_INVOICES_SUMMARY(state, unpaid_invoices_summary) {
      state.unpaid_invoices_summary = unpaid_invoices_summary;
    },
    SET_SALES_SUMMARY(state, sales_summary) {
      state.sales_summary = sales_summary;
    },
    SET_summary(state, summary) {
      state.summary = summary;
    },
    SET_YEARLY_SUMMARY(state, yearly_summary) {
      state.yearly_summary = yearly_summary;
    },
    SET_PICKED_UP_UNITS(state, picked_up_units) {
      state.picked_up_units = picked_up_units;
    },
  },
  actions: {
    async get_unpaid_invoices_summary({ commit }) {
      let response = await axios.get("/get_unpaid_invoices");

      commit("SET_UNPAID_INVOICES_SUMMARY", response.data);
    },
    async get_summary({ commit }) {
      let response = await axios.get("/summary");
      commit("SET_summary", response.data);
    },
    async get_sales_summary({ commit }) {
      let response = await axios.get("/sales_summary");
      commit("SET_SALES_SUMMARY", response.data);
    },
    async get_yearly_summary({ commit }) {
      let response = await axios.get("/yearly_summary");
      commit("SET_YEARLY_SUMMARY", response.data);
    },
    async get_picked_up_units({ commit }) {
      let response = await axios.get("/get_picked_up_units");
      commit("SET_PICKED_UP_UNITS", response.data);
    },
  },
};
