import axios from "axios";
export default {
  namespaced: true,
  state: {
    services: [],
  },
  getters: {
    services(state) {
      return state.services;
    },
  },
  mutations: {
    DELETE_SERVICE(state, service_id) {
      let filtered = state.services.filter((c) => {
        return c.id != service_id;
      });
      state.services = filtered;
    },
    SET_SERVICES(state, services) {
      state.services = services;
    },
    ADD_SERVICE(state, service) {
      state.services.unshift(service);
    },
    EDIT_SERVICE(state, service) {
      var index = state.services.findIndex((a) => a.id == service.id);
      state.services.splice(index, 1, service);
    },
  },
  actions: {
    async delete_service({ commit }, service_id) {
      await axios.delete("/service/" + service_id);
      commit("DELETE_SERVICE", service_id);
    },
    async get_services({ commit }) {
      let response = await axios.get("/services");
      commit("SET_SERVICES", response.data);
    },
    async add_service({ commit }, request) {
      let response = await axios.post("/service", request);
      commit("ADD_SERVICE", response.data);
    },
    async edit_service({ commit }, request) {
      let response = await axios.put("/service", request);
      commit("EDIT_SERVICE", response.data);
    },
  },
};
