<template>
  <div>
    <v-btn @click="dialog = true">Add Reminder</v-btn>
    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-card-title><h3>Add Reminder</h3></v-card-title>
        <v-card-text>
          <v-select
            prepend-inner-icon="mdi-pencil-circle"
            label="Reminder Type"
            v-model="form.reminder_type"
            :items="[
              'Admin Reminder',
              'Home Service',
              'Back Job',
              'Review Job Order',
              'Others',
            ]"
          ></v-select>
          <v-text-field
            label="Reminder Description"
            v-model="form.reminder_description"
            prepend-inner-icon="mdi-pencil"
          ></v-text-field>
          <h3>Reminder Time and Date</h3>
          <div class="d-flex">
            <input type="date" v-model="form.reminder_date" />
            <input type="time" v-model="form.reminder_time" />
            <v-select
              class="ml-2"
              prepend-inner-icon="mdi-bell-circle-outline"
              v-model="form.level_of_urgency"
              label="Level of Urgency"
              :items="['Basic', 'Urgent', 'Very Urgent!']"
            ></v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" color="primary" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      add_reminder: "reminder/add_reminder",
    }),
    submit() {
      let x = window.confirm("Are you sure you want to add this reminder?");
      if (x) {
        this.loading = true;
        this.add_reminder(this.form)
          .then(() => {
            alert("New reminder added!");
            this.loading = false;
          })
          .catch((err) => {
            alert(err);
            this.loading = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters({}),
  },
  created() {},
  data() {
    return {
      loading: false,
      form: {},
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
