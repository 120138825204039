<template>
  <div>
    <v-btn @click="dialog = true" small
      ><v-icon>mdi-plus</v-icon> Add Return</v-btn
    >
    <v-dialog v-model="dialog" persistent width="650">
      <v-card>
        <v-card-title>Add Return Item</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              label="Item Name"
              v-model="all_items_search"
            ></v-text-field>

            <v-select
              :items="employees"
              item-text="name"
              label="Declared by"
              v-model="form.technician"
            ></v-select>

            <v-data-table
              :items="all_items"
              :headers="all_items_headers"
              :search="all_items_search"
              :options="all_items_options"
            >
              <template v-slot:item.action="{ item }">
                <v-btn small @click="select(item)">Select</v-btn>
              </template>
            </v-data-table>

            <div class="d-flex">
              <v-text-field
                v-model="form.item_name"
                readonly
                label="Item Name"
                class="mr-5"
              ></v-text-field>
              <v-text-field
                class="mr-5"
                label="Quantity"
                v-model="form.quantity"
              ></v-text-field>
              <v-text-field
                label="S/N"
                v-model="form.product_serial_number"
              ></v-text-field>
            </div>
            <v-textarea
              v-model="form.problem_description"
              label="Problem Description"
              placeholder="Please state the item's defect clearly. Also include the troubleshooting done."
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">addReturnItemModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import CustomDatePicker from "../customDatePicker.vue";
export default {
  //   components: { CustomDatePicker },
  data() {
    return {
      all_items_options: {
        itemsPerPage: 3,
      },
      all_items_search: "",
      all_items_headers: [
        { text: "", value: "action" },
        { text: "Id", value: "id" },
        { text: "Item Name", value: "item_name" },
        { text: "Batch Import", value: "import_batch.batch_description" },
      ],
      dialog: false,
      loading: false,
      form: { item_id: "", item_name: "", quantity: 1 },
    };
  },
  computed: {
    ...mapGetters({
      all_items: "item/all_items",
      employees: "employee/employees",
    }),
  },
  methods: {
    ...mapActions({
      add_returned_item: "item/add_returned_item",
      get_employees: "employee/get_employees",
      get_items: "item/get_items",
    }),
    submit() {
      let x = window.confirm("Are you sure you want to add this entry?");
      if (x) {
        this.loading = true;
        this.add_returned_item(this.form).then(() => {
          alert("Entry Added");
        });
        this.loading = false;
      }
    },
    select(item) {
      this.form.item_id = item.id;
      this.form.item_name = item.item_name;
    },
  },
  created() {
    if (this.employees.length == 0) {
      this.get_employees();
    }
    if (this.all_items.length == 0) {
      this.get_items();
    }
  },
};
</script>

<style lang="scss" scoped></style>
