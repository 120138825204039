import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueHtmlToPaper from "vue-html-to-paper";
import firebase from "firebase/compat/app";
import "firebase/storage";
const options = {
  styles: [
    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
  ],
  timeout: 50000,
};
const firebaseConfig = {
  apiKey: "AIzaSyCTNzR1tInyiCytA2j7t4bxiA-bwDlmUus",
  authDomain: "jmbcomputers.firebaseapp.com",
  projectId: "jmbcomputers",
  storageBucket: "jmbcomputers.appspot.com",
  messagingSenderId: "420884036697",
  appId: "1:420884036697:web:faa0cc9667b8346e5d4548",
  measurementId: "G-5YSCMPCQJ1",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.use(VueHtmlToPaper, options);

// for authenticating thru headers with tokens
require("@/store/subscriber");
Vue.config.productionTip = false;
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";
// axios.defaults.baseURL = "http://192.168.1.69:8000/api";
axios.defaults.baseURL = "https://server.jmbcomputers.com/api";
store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
