import axios from "axios";
export default {
  namespaced: true,
  state: {
    journal_entries: [],
    journal_entry: null,
    general_ledger_entries: [],
    ledger_postings: [],
  },
  getters: {
    journal_entries(state) {
      return state.journal_entries;
    },
    journal_entry(state) {
      return state.journal_entry;
    },
    general_ledger_entries(state) {
      return state.general_ledger_entries;
    },
    ledger_postings(state) {
      return state.ledger_postings;
    },
  },
  mutations: {
    ADD_LEDGER_POSTING(state, ledger_posting) {
      state.ledger_postings.unshift(ledger_posting);
    },
    SET_LEDGER_POSTINGS(state, ledger_postings) {
      state.ledger_postings = ledger_postings;
    },
    SET_GENERAL_LEDGER_ENTRIES(state, general_ledger_entries) {
      // let balance = 0;
      let balance = 0; // Initialize balance outside the loop

      general_ledger_entries.forEach((g) => {
        // get normal balance
        let normal_balance = g.chart_of_account.normal_balance;

        if (normal_balance == "debit") {
          if (g.is_debit == 1) {
            g.balance = balance += g.amount;
          } else {
            g.balance = balance -= g.amount;
          }
        } else {
          if (g.is_debit == 1) {
            g.balance = balance -= g.amount;
          } else {
            g.balance = balance += g.amount;
          }
        }
      });

      state.general_ledger_entries = general_ledger_entries;
      // state.general_ledger_entries = general_ledger_entries;
    },
    SET_JOURNAL_ENTRY(state, journal_entry) {
      state.journal_entry = journal_entry;
    },
    SET_JOURNAL_ENTRIES(state, journal_entries) {
      state.journal_entries = journal_entries;
    },
    ADD_JOURNAL_ENTRY(state, journal_entry) {
      state.journal_entries.unshift(journal_entry);
    },
    EDIT_JOURNAL_ENTRY(state, journal_entry) {
      var index = state.journal_entries.findIndex(
        (a) => a.id == journal_entry.id
      );
      state.journal_entries.splice(index, 1, journal_entry);
    },
    DELETE_JOURNAL_ENTRY(state, journal_entry) {
      let filtered = state.journal_entries.filter((c) => {
        return c.id != journal_entry.id;
      });
      state.journal_entries = filtered;
    },
  },
  actions: {
    async get_income_statement(_, request) {
      let response = await axios.get(
        "/get_income_statement/" + request.month + "/" + request.year
      );
      return response.data;
    },
    async get_ledger_postings({ commit }, request) {
      let response = await axios.get(
        "/get_ledger_postings/" + request.month + "/" + request.year
      );
      commit("SET_LEDGER_POSTINGS", response.data);
    },

    async add_ledger_posting({ commit }, request) {
      let response = await axios.post("/ledger_posting", request);
      commit("ADD_LEDGER_POSTING", response.data);
    },

    async get_general_ledger_entries(_, request) {
      let response = await axios.get(
        "/get_general_ledger_entries/" +
          request.chart_of_account_id +
          "/" +
          request.month +
          "/" +
          request.year
      );
      return response.data;
    },
    async get_journal_entry({ commit }, journal_entry_code) {
      let response = await axios.get(
        "/journal_entry_info/" + journal_entry_code
      );

      commit("SET_JOURNAL_ENTRY", response.data);
    },
    // get journal_entries
    async get_journal_entries({ commit }) {
      let response = await axios.get("/journal_entries");
      commit("SET_JOURNAL_ENTRIES", response.data);
    },
    async add_journal_entry({ commit }, request) {
      let response = await axios.post("/journal_entry", request);
      commit("ADD_JOURNAL_ENTRY", response.data);
    },
    async edit_journal_entry({ commit }, request) {
      let response = await axios.put("/journal_entry", request);
      commit("EDIT_JOURNAL_ENTRY", response.data);
    },
    async delete_journal_entry({ commit }, request) {
      await axios.delete("/journal_entry/" + request.id);
      commit("DELETE_JOURNAL_ENTRY", request);
    },
  },
};
