<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="700">
      <v-card>
        <v-card-title>Add Item Order</v-card-title>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            v-model="search"
          ></v-text-field>

          <v-data-table
            :items="customers"
            :headers="customer_headers"
            :search="search"
            :options="{ itemsPerPage: 5 }"
          >
            <template v-slot:item.action="{ item }">
              <v-btn x-small @click="select(item)">Select</v-btn>
            </template>
          </v-data-table>

          <v-row>
            <v-col>
              <v-text-field
                placeholder="Customers Name"
                prepend-icon="mdi-account-circle"
                readonly
                v-model="form.customer_name"
              ></v-text-field>

              <v-select
                :items="item_types"
                placeholder="Item Type"
                prepend-icon="mdi-printer"
                v-model="form.item_type"
              ></v-select>
              <v-text-field
                placeholder="Item Name"
                v-model="form.item_name"
                prepend-icon="mdi-pen"
              ></v-text-field>
              <v-select
                :items="users"
                item-text="full_name"
                prepend-icon="mdi-account-wrench"
                placeholder="Technician"
                v-model="form.technician"
              ></v-select>
              <v-text-field
                placeholder="Unit Price"
                v-model="form.unit_price"
                type="number"
                prepend-icon="mdi-cash"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-text-field
                placeholder="Profitable Margin"
                v-model="form.profitable_margin"
                type="number"
                prepend-icon="mdi-percent"
                @input="calculate_selling_price"
              ></v-text-field>
              <v-text-field
                placeholder="Selling Price"
                v-model="form.selling_price"
                type="number"
                prepend-icon="mdi-cash-multiple"
              ></v-text-field>
              <v-row>
                <v-col cols="9">
                  <v-select
                    placeholder="Supplier"
                    :items="suppliers"
                    item-text="supplier_name"
                    v-model="form.supplier"
                    prepend-icon="mdi-account-outline"
                  ></v-select
                ></v-col>
                <v-col> <add-supplier-modal /></v-col>
              </v-row>

              <v-text-field
                placeholder="Remarks"
                prepend-icon="mdi-pen"
                v-model="form.remarks"
              ></v-text-field>
              <v-row>
                <v-col>
                  <p>Date Ordered</p>
                  <input
                    type="date"
                    placeholder="Date Ordered"
                    v-model="form.date_ordered"
                  />
                </v-col>
                <v-col
                  ><v-btn small color="yellow" @click="set_item" class="mt-8"
                    >Set Item</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <!-- <v-btn color="success">Save Item</v-btn> -->
          <add-item-modal :item="this.form" v-if="this.form.remarks !== ''" />
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddItemModal from "../Item/addItemModal.vue";
import AddSupplierModal from "../Supplier/addSupplierModal.vue";
export default {
  components: { AddSupplierModal, AddItemModal },
  data() {
    return {
      selected_customer_name: "",
      search: "",
      dialog: false,
      loading: false,
      form: {
        customer_name: "",
        item_type: "",
        item_name: "",
        technician: "",
        unit_price: "",
        selling_price: "",
        supplier: "",
        date_ordered: "",
        remarks: "",
        profitable_margin: "",
      },
      item_types: ["Printer", "Laptop", "Desktop", "CCTV", "Others"],
      customer_headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Customer Name", value: "customer_name" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customers: "customer/customers",
      users: "auth/users",
      suppliers: "supplier/suppliers",
      import_batches: "importBatch/import_batches",
    }),
  },
  methods: {
    set_item() {
      this.set_item_order_for_add(this.form).then(() => {
        alert("item set successfully");
      });
    },
    calculate_selling_price() {
      // unit_price + percentage + shipping cost
      let sell = (this.form.unit_price * this.form.profitable_margin) / 100;

      let partial = Math.round(parseInt(this.form.unit_price) + sell);
      this.form.selling_price = partial;
    },
    some_fields_are_empty() {
      console.log("triggered", this.form);

      return Object.values(this.form).some((value) => value === "");
    },
    select(item) {
      this.form.customer_name = item.customer_name;
    },
    submit() {
      this.loading = true;
      if (this.some_fields_are_empty()) {
        alert("Please check all fields");
        this.loading = false;
        return;
      }
      let x = window.confirm("Are you sure you want to place this order?");
      if (x) {
        this.add_item_order(this.form).then(() => {
          alert("Successfully placed an order");
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    ...mapActions({
      add_item_order: "item/add_item_order",
      get_import_batches: "importBatch/get_import_batches",
      set_item_order_for_add: "item/set_item_order_for_add",
    }),
  },
  created() {
    if (this.import_batches.length == 0) return this.get_import_batches();
  },
};
</script>

<style lang="scss" scoped></style>
