var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Finished Tasks")]),_c('v-text-field',{attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"items":_vm.finished_tasks,"headers":_vm.reminder_headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.level_of_urgency",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.color_code(item.level_of_urgency)}},[_vm._v(_vm._s(item.level_of_urgency == "Basic" ? "mdi-calendar" : item.level_of_urgency == "Very Urgent!" ? "mdi-alert-circle" : "mdi-calendar"))]),_c('span',{class:item.level_of_urgency == 'Basic'
            ? 'primary--text font-weight-bold'
            : item.level_of_urgency == 'Very Urgent!'
            ? 'error--text font-weight-bold'
            : 'warning--text font-weight-bold'},[_vm._v(_vm._s(item.level_of_urgency))])]}},{key:"item.reminder_type",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.reminder_type == "Admin Reminder" ? "mdi-shield-account" : item.reminder_type == "Home Service" ? "mdi-home" : item.reminder_type == "Back Job" ? "mdi-tools" : "mdi-pencil")+" ")]),_c('span',[_vm._v(_vm._s(item.reminder_type))])]}},{key:"item.is_finished",fn:function(ref){
            var item = ref.item;
return [(item.is_finished == 1)?_c('span',{staticClass:"success--text"},[_vm._v(" Task Done ")]):_c('span',{staticClass:"warning--text"},[_vm._v(" Not yet executed ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }