import axios from "axios";
export default {
  namespaced: true,
  state: {
    invoices: [],
    all_invoices: [],
    all_bills: [],
    unpaid_invoices: [],
  },
  getters: {
    invoices(state) {
      return state.invoices;
    },
    all_invoices(state) {
      return state.all_invoices;
    },
    all_bills(state) {
      return state.all_bills;
    },
    unpaid_invoices(state) {
      return state.unpaid_invoices;
    },
  },
  mutations: {
    SET_ALL_INVOICES(state, all_invoices) {
      state.all_invoices = all_invoices;
    },

    SET_ALL_BILLS(state, all_bills) {
      state.all_bills = all_bills;
    },

    SET_INVOICES(state, invoices) {
      state.invoices = invoices;
    },
    ADD_INVOICE(state, invoice) {
      if (invoice.is_invoice == 1) {
        state.all_invoices.unshift(invoice);
      } else {
        state.all_bills.unshift(invoice);
      }
    },
    EDIT_INVOICE(state, invoice) {
      var index = state.all_invoices.findIndex((a) => a.id == invoice.id);
      state.all_invoices.splice(index, 1, invoice);
    },
    DELETE_INVOICE(state, request) {
      var filtered;
      if (request.is_invoice == 1) {
        filtered = state.all_invoices.filter((i) => {
          return i.id != request.id;
        });
        state.all_invoices = filtered;
      } else {
        filtered = state.all_bills.filter((i) => {
          return i.id != request.id;
        });
        state.all_bills = filtered;
      }
    },
    SET_UNPAID_INVOICES(state, unpaid_invoices) {
      state.unpaid_invoices = unpaid_invoices;
    },
    DELETE_INVOICE_ITEM(state, request) {
      const { payable_id, invoice_id, is_quote } = request;
      const invoice = state.invoices.find(
        (invoice) => invoice.id === invoice_id
      );
      if (invoice) {
        const payables = is_quote ? invoice.quoteables : invoice.payables;
        const index = payables.findIndex(
          (payable) => payable.id === payable_id
        );
        if (index !== -1) {
          payables.splice(index, 1);
        }
      }
    },
  },
  actions: {
    async get_all_bills({ commit }) {
      let response = await axios.get("/all_bills");
      commit("SET_ALL_BILLS", response.data);
    },

    async get_all_invoices({ commit }) {
      let response = await axios.get("/all_invoices");
      commit("SET_ALL_INVOICES", response.data);
    },
    async get_unpaid_invoices({ commit }) {
      let response = await axios.get("/unpaid_invoices");
      commit("SET_UNPAID_INVOICES", response.data);
    },
    async get_invoices({ commit }, customer_id) {
      let response = await axios.get("/invoices/" + customer_id);
      commit("SET_INVOICES", response.data);
    },
    async add_invoice({ commit }, request) {
      let response = await axios.post("/invoice", request);
      commit("ADD_INVOICE", response.data);
    },
    async edit_invoice({ commit }, request) {
      commit("EDIT_INVOICE", request);
    },
    async delete_invoice({ commit }, request) {
      var id = request.id;
      await axios.delete("/invoice/" + id).catch((e) => alert(e));
      commit("DELETE_INVOICE", request);
    },
    async add_item_to_invoice_items({ commit }, request) {
      let response = await axios.post("/add_item_to_invoice_items", request);
      commit("EDIT_INVOICE", response.data);
    },
    async delete_invoice_item(_, invoice_item_id) {
      await axios.delete("/delete_invoice_item/" + invoice_item_id);
      // commit("EDIT_INVOICE", response.data);
      // commit("DELETE_PAYABLE_FROM_INVOICE", request);
    },
  },
};
