import axios from "axios";
export default {
  namespaced: true,
  state: {
    expenses: [],
  },
  getters: {
    expenses(state) {
      return state.expenses;
    },
  },
  mutations: {
    SET_EXPENSES(state, expenses) {
      state.expenses = expenses;
    },
    ADD_EXPENSE(state, expense) {
      state.expenses.unshift(expense);
    },
    EDIT_EXPENSE(state, expense) {
      var index = state.expenses.findIndex((a) => a.id == expense.id);
      state.expenses.splice(index, 1, expense);
    },
    DELETE_EXPENSE(state, expense) {
      let filtered = state.expenses.filter((c) => {
        return c.id != expense.id;
      });
      state.expenses = filtered;
    },
  },
  actions: {
    // get expenses
    async get_expenses({ commit }) {
      let response = await axios.get("/expenses");
      commit("SET_EXPENSES", response.data);
    },
    async add_expense({ commit }, request) {
      let response = await axios.post("/expense", request);
      commit("ADD_EXPENSE", response.data);
    },
    async edit_expense({ commit }, request) {
      let response = await axios.put("/expense", request);
      commit("EDIT_EXPENSE", response.data);
    },
    async delete_expense({ commit }, request) {
      await axios.delete("/expense/" + request.id);
      commit("DELETE_EXPENSE", request);
    },
  },
};
