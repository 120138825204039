import axios from "axios";
export default {
  namespaced: true,
  state: {
    item_types: [],
  },
  getters: {
    item_types(state) {
      return state.item_types;
    },
  },
  mutations: {
    SET_ITEM_TYPES(state, item_types) {
      state.item_types = item_types;
    },
    ADD_ITEM_TYPE(state, item_type) {
      state.item_types.unshift(item_type);
    },

    EDIT_ITEM_TYPE(state, item_type) {
      var index = state.item_types.findIndex((a) => a.id == item_type.id);
      state.item_types.splice(index, 1, item_type);
    },
    DELETE_ITEM_TYPE(state, request) {
      var filtered;

      filtered = state.item_types.filter((i) => {
        return i.id != request.id;
      });
      state.item_types = filtered;
    },
  },
  actions: {
    async get_item_types({ commit }) {
      let response = await axios.get("/item_types");
      commit("SET_ITEM_TYPES", response.data);
    },
    add_item_type({ commit }, request) {
      axios
        .post("/item_type", request)
        .then((res) => {
          commit("ADD_ITEM_TYPE", res.data);
          alert("Successfully added a new item type");
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    async edit_item_type({ commit }, request) {
      let response = await axios.put("/item_type", request);
      commit("EDIT_ITEM_TYPE", response.data);
    },
    async delete_item_type({ commit }, request) {
      var id = request.id;
      await axios.delete("/item_type/" + id).catch((e) => alert(e));
      commit("DELETE_ITEM_TYPE", request);
    },
  },
};
