<template>
  <div>
    <v-btn color="green" class="mt-3" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Supplier Data</v-card-title>
        <v-card-text>
          <v-text-field
            label="Supplier Name"
            v-model="form.supplier_name"
            prepend-icon="mdi-domain"
          ></v-text-field>
          <v-text-field
            label="Contact Person"
            prepend-icon="mdi-account-tie"
            v-model="form.contact_person"
          ></v-text-field>
          <v-text-field
            label="Supplier Contact"
            v-model="form.supplier_contact_no"
            prepend-icon="mdi-phone"
          ></v-text-field>
          <v-text-field
            label="Contact Platform"
            v-model="form.contact_platform"
            prepend-icon="mdi-cellphone-message"
          ></v-text-field>
          <v-text-field
            label="Supplier Address"
            v-model="form.supplier_address"
            prepend-icon="mdi-map-marker"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-percent"
            label="Vat Percentage"
            v-model="form.vat_percentage"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-cart"
            label="Item Sales"
            v-model="form.item_sales"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" color="primary" :loading="loading">Add</v-btn>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        supplier_name: "",
        supplier_contact_no: "",
        supplier_address: "",
      },
    };
  },
  methods: {
    ...mapActions({
      add_supplier: "supplier/add_supplier",
    }),
    async submit() {
      this.loading = true;
      await this.add_supplier(this.form);
      alert("You have successfully added a new supplier!");
      this.loading = false;
      //   console.log(this.form);
    },
  },
};
</script>

<style lang="scss" scoped></style>
