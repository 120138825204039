import axios from "axios";
export default {
  namespaced: true,
  state: {
    employees: [],
  },
  getters: {
    employees(state) {
      return state.employees;
    },
  },
  mutations: {
    SET_EMPLOYEES(state, employees) {
      state.employees = employees;
    },
    ADD_EMPLOYEE(state, employee) {
      state.employees.unshift(employee);
    },
    EDIT_EMPLOYEE(state, employee) {
      var index = state.employees.findIndex((a) => a.id == employee.id);
      state.employees.splice(index, 1, employee);
    },
    DELETE_EMPLOYEE(state, employee) {
      let filtered = state.employees.filter((c) => {
        return c.id != employee.id;
      });
      state.employees = filtered;
    },
  },
  actions: {
    // get employees
    async get_employees({ commit }) {
      let response = await axios.get("/employees");
      commit("SET_EMPLOYEES", response.data);
    },
    async add_employee({ commit }, request) {
      let response = await axios.post("/employee", request);
      commit("ADD_EMPLOYEE", response.data);
    },
    async edit_employee({ commit }, request) {
      let response = await axios.put("/employee", request);
      commit("EDIT_EMPLOYEE", response.data);
    },
    async delete_employee({ commit }, request) {
      await axios.delete("/employee/" + request.id);
      commit("DELETE_EMPLOYEE", request);
    },
  },
};
