<template>
  <div>
    <v-btn
      color="yellow"
      class="mr-2"
      v-if="item_transfer"
      @click="dialog = true"
      >Add New</v-btn
    >
    <v-btn color="green" class="" icon x-large @click="dialog = true" v-else
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >

    <v-dialog v-model="dialog" persistent width="650">
      <v-card class="pa-5">
        <v-card-title>
          <h2>Add Item</h2>
          <v-spacer></v-spacer>
          <v-btn small color="yellow" @click="set_item_for_add">Get Item</v-btn>
          <v-btn small color="" @click="get_coa" class="mr-2">Get COAs</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="photo in item_photos"
              :key="photo.item_photo_url"
              class="d-flex child-flex"
              cols="6"
            >
              <v-img
                :src="photo.item_photo_url"
                lazy-src="@/assets/jmbcircle.png"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <v-btn
                  small
                  class="ma-2 d-flex justify-end"
                  @click="delete_item_photo(photo.item_photo_url)"
                >
                  <v-icon color="red">mdi-delete</v-icon></v-btn
                >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-form ref="form">
            <div class="mt-10 d-flex mb-10">
              <input
                class="black--text"
                type="file"
                ref="photo_upload"
                name="photo_upload"
                @change="insert_image"
                accept="image/jpeg"
              />
              <v-btn
                small
                class=""
                color=""
                @click="upload_image"
                :loading="uploading"
              >
                Upload Picture</v-btn
              >
            </div>
            <v-select
              label="Account Title"
              :items="chart_of_accounts"
              item-text="account_title"
              item-value="id"
              v-model="form.chart_of_account_id"
            ></v-select>
            <v-text-field
              label="Item Name"
              v-model="form.item_name"
            ></v-text-field>
            <v-select
              :items="item_types"
              label="Item Type"
              v-model="form.item_type_id"
              item-text="item_type"
              item-value="id"
            ></v-select>
            <v-text-field
              v-if="show_other"
              v-model="form.item_type"
              label="Other type"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-cash"
              type="number"
              label="Unit Price"
              v-model="form.unit_price"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-cash"
              type="number"
              label="Unit Price"
              v-model="form.unit_price"
            ></v-text-field>
            <v-textarea
              prepend-icon="mdi-pen"
              type="text"
              label="Item Description"
              v-model="form.item_description"
            ></v-textarea>

            <v-text-field
              type="number"
              prepend-icon="mdi-percent"
              label="Profitable Margin by Percentage"
              v-model="form.profitable_margin"
            >
            </v-text-field>
            <v-text-field
              prepend-icon="mdi-truck-delivery"
              type="number"
              label="Shipping Cost"
              v-model="form.shipping_cost"
              @input="calculate_selling_price"
            ></v-text-field>
            <v-radio-group v-model="form.is_vat" row label="Tax Option">
              <v-radio label="Non-VAT" :value="0"></v-radio>
              <v-radio label="VAT" :value="1"></v-radio>
            </v-radio-group>
            <v-text-field
              prepend-icon="mdi-cash-multiple"
              type="number"
              label="Selling Price"
              v-model="form.selling_price"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-package-variant-closed-plus"
              type="number"
              label="Initial Import"
              v-model="form.initial_import"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-package"
              type=""
              label="Unit"
              v-model="form.unit"
            ></v-text-field>
            <v-select
              prepend-icon="mdi-account-hard-hat"
              :items="suppliers"
              label="Supplier"
              item-text="supplier_name"
              item-value="id"
              v-model="form.supplier_id"
            ></v-select>
            <div class="d-flex">
              <v-text-field
                label="Warranty Count"
                type="number"
                class="mr-5"
                v-model="form.warranty_count"
              ></v-text-field>
              <v-select
                v-model="form.warranty_duration"
                label="Warranty Duration"
                :items="['Day/s', 'Week/s', 'Months/s', 'Year']"
              ></v-select>
            </div>
            <div class="d-flex">
              <v-select
                v-model="form.import_batch_id"
                label="Batch Import Description"
                :items="import_batches"
                item-value="id"
                item-text="batch_description"
              ></v-select>
              <add-batch-import-modal />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit_item" :loading="loading">Add</v-btn>
          <v-btn @click="dialog = false">Close</v-btn>

          <v-spacer></v-spacer>
          <p class="caption grey--text">addItemModal.vue</p>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { mapGetters, mapActions } from "vuex";
import addBatchImportModal from "../BatchImport/addBatchImportModal.vue";
import axios from "axios";
// import axios from "axios";
export default {
  props: ["item_transfer", "quantity", "item"],
  components: { addBatchImportModal },
  data() {
    return {
      show_other: false,
      item_photos: [],
      picture: null,
      picture_name: "",
      picture_url: "",
      uploading: false,
      loading: false,
      ready: false,
      dialog: false,
      form: {
        item_name: "",
        item_type_id: "",
        unit_price: "",
        selling_price: "",
        date_received: "",
        supplier_id: "",
        warranty_count: "",
        initial_import: "",
        warranty_duration: "",
        profitable_margin: "",
        shipping_cost: "",
        item_description: "",
        vat: 0,
        is_vat: 0,
        unit: "PCS",
        import_batch_id: "",
        chart_of_account_id: "",
        branch_id: "",
      },
    };
  },
  methods: {
    delete_item_photo(item_photo_url) {
      // filter photos
      let x = window.confirm("Are you sure you want to remove this photo?");
      if (x) {
        let filtered = this.item_photos.filter((i) => {
          return i.item_photo_url != item_photo_url;
        });
        this.item_photos = filtered;
      }
    },
    set_item_for_add() {
      this.form.item_name = this.item_order_for_add.item_name;
      this.form.item_type_id = this.item_order_for_add.item_type.id;
      this.form.unit_price = this.item_order_for_add.unit_price;
      this.form.selling_price = this.item_order_for_add.selling_price;
      this.form.shipping_cost = this.item_order_for_add.shipping_cost;
      this.form.profitable_margin = this.item_order_for_add.profitable_margin;
      this.form.item_description = this.item_order_for_add.item_description;
      this.form.import_batch_id = this.item_order_for_add.import_batch.id;
      this.form.supplier_id = this.item_order_for_add.supplier.id;
      this.form.warranty_count =
        this.item_order_for_add.warranty.warranty_count;
      this.form.warranty_duration =
        this.item_order_for_add.warranty.warranty_duration;
      this.form.initial_import = this.quantity;
      this.form.chart_of_account_id =
        this.item_order_for_add.chart_of_account.id;
      this.item_photos = this.item_order_for_add.item_photos;
      this.form.is_vat = this.item_order_for_add.is_vat;
    },
    merge() {
      const modifyStock = (itemId, quantity, action) =>
        axios.put(`/modify_stock/${itemId}/${quantity}/${action}`);

      const handleError = (err) => alert(err);

      // Deduct from the original branch
      modifyStock(this.item.item_id, this.item.quantity, "subtract")
        .then(
          (res) => {
            // update current items table
            this.edit_item(res.data);
            this.add_item_transfer(this.item)
              .then(() => {
                this.$emit("close");
                alert("Successfully processed a new item transfer");
              })
              .catch(handleError);
          }
          // add item transfer record
        )
        .catch(handleError);
    },
    async upload_image() {
      // upload to firestore
      if (this.picture == "")
        return alert("Please insert image before adding item");
      this.uploading = true;
      const storage = getStorage();
      const storageRef = ref(storage, "ItemPhotos/" + this.picture_url);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, this.picture).then(() => {
        getDownloadURL(storageRef).then((downloadURL) => {
          let request = {
            item_photo_url: downloadURL,
            photo_name: this.picture_name,
          };
          // insert to array
          this.item_photos.push(request);
          this.uploading = false;
        });
      });
    },
    async upload_image_to_db(request) {
      await axios
        .post("/item_photo", request)
        .then(() => {
          console.log("Image Successfully uploaded");
        })
        .catch((err) => {
          console.log("uploading image error:" + err);
        });
    },
    insert_image(e) {
      this.picture = e.target.files[0];
      this.picture_url = URL.createObjectURL(this.picture);
      this.picture_name = this.picture.name;
    },
    get_coa() {
      this.loading = true;
      this.get_chart_of_accounts().then(() => {
        this.loading = false;
      });
    },

    ...mapActions({
      get_item_types: "item_type/get_item_types",
      get_items: "item/get_items",
      get_suppliers: "supplier/get_suppliers",
      get_import_batches: "importBatch/get_import_batches",
      add_item: "item/add_item",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
      add_item_transfer: "item_transfer/add_item_transfer",
      edit_item: "item/edit",
    }),

    calculate_selling_price() {
      // unit_price + percentage + shipping cost
      let sell = (this.form.unit_price * this.form.profitable_margin) / 100;
      let partial = Math.round(parseInt(this.form.unit_price) + sell);
      this.form.selling_price = partial + parseInt(this.form.shipping_cost);
    },
    submit_item() {
      this.loading = true;
      this.add_item(this.form)
        .then((res) => {
          // insert images from item_photos array to db
          this.item_photos.forEach((p) => {
            // request
            let request = {
              item_id: res.id,
              item_photo_url: p.item_photo_url,
              photo_name: p.photo_name,
            };
            this.upload_image_to_db(request);
          });
          this.loading = false;
          alert("Successfully inserted a new item");
        })
        .catch((err) => {
          alert(err);
          this.loading = false;
        });
      if (this.item_transfer) this.merge(this.form);
    },
  },
  computed: {
    ...mapGetters({
      item_types: "item_type/item_types",
      suppliers: "supplier/suppliers",
      import_batches: "importBatch/import_batches",
      chart_of_accounts: "chart_of_account/chart_of_accounts",
      item_order_for_add: "item/item_order_for_add",
      user: "auth/user",
    }),
  },
  async created() {
    await this.get_item_types();
    await this.get_items();
    await this.get_suppliers();
    await this.get_import_batches();
    await this.get_chart_of_accounts();
    this.ready = true;

    if (this.item_transfer) {
      this.form.branch_id = this.item.to_branch_id;
      this.form.modal_from = "item transfer";
    } else {
      // insert branch id to form
      this.form.branch_id = this.user.branch_id;
    }
  },
};
</script>

<style lang="scss" scoped></style>
