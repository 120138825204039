import axios from "axios";
export default {
  namespaced: true,
  state: {
    chart_of_accounts: [],
    chart_of_accounts_total: [],
  },
  getters: {
    chart_of_accounts(state) {
      return state.chart_of_accounts;
    },
    chart_of_accounts_total(state) {
      return state.chart_of_accounts_total;
    },
  },
  mutations: {
    SET_CHART_OF_ACCOUNTS_TOTAL(state, chart_of_accounts_total) {
      state.chart_of_accounts_total = chart_of_accounts_total;
    },
    SET_CHART_OF_ACCOUNTS(state, chart_of_accounts) {
      state.chart_of_accounts = chart_of_accounts;
    },
    ADD_CHART_OF_ACCOUNT(state, chart_of_account) {
      state.chart_of_accounts.unshift(chart_of_account);
    },
    EDIT_CHART_OF_ACCOUNT(state, chart_of_account) {
      var index = state.chart_of_accounts.findIndex(
        (a) => a.id == chart_of_account.id
      );
      state.chart_of_accounts.splice(index, 1, chart_of_account);
    },
    DELETE_CHART_OF_ACCOUNT(state, chart_of_account) {
      let filtered = state.chart_of_accounts.filter((c) => {
        return c.id != chart_of_account.id;
      });
      state.chart_of_accounts = filtered;
    },
  },
  actions: {
    // get chart_of_accounts
    async get_chart_of_accounts_total({ commit }, month) {
      let response = await axios.get("/get_chart_of_accounts_total/" + month);
      commit("SET_CHART_OF_ACCOUNTS_TOTAL", response.data);
    },
    async get_chart_of_accounts({ commit }) {
      let response = await axios.get("/chart_of_accounts");
      commit("SET_CHART_OF_ACCOUNTS", response.data);
    },
    async add_chart_of_account({ commit }, request) {
      let response = await axios.post("/chart_of_account", request);
      commit("ADD_CHART_OF_ACCOUNT", response.data);
    },
    async edit_chart_of_account({ commit }, request) {
      let response = await axios.put("/chart_of_account", request);
      commit("EDIT_CHART_OF_ACCOUNT", response.data);
    },
    async delete_chart_of_account({ commit }, request) {
      await axios.delete("/chart_of_account/" + request.id);
      commit("DELETE_CHART_OF_ACCOUNT", request);
    },
  },
};
