<template>
  <div>
    <v-btn text small class="mr-2" color="pink" @click="dialog = true">
      <v-badge color="red" :content="pending_tasks" v-if="pending_tasks > 0">
        <v-icon class="mr-2">mdi-calendar-month</v-icon> Reminders</v-badge
      >
      <span v-else>
        <v-icon class="mr-2">mdi-calendar-month</v-icon> Reminders</span
      >
    </v-btn>
    <v-dialog v-model="dialog" persistent width="1050">
      <v-card>
        <v-card-title
          ><h2 class="pink--text">Reminders</h2>
          <v-spacer></v-spacer>

          <add-reminder-modal />
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-4"></v-divider>
          <pending-reminders />
          <all-reminders />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import moment from "moment";

import addReminderModal from "./addReminderModal.vue";
import AllReminders from "./Reminder/allReminders.vue";
import PendingReminders from "./Reminder/pendingReminders.vue";

export default {
  components: {
    addReminderModal,
    AllReminders,
    PendingReminders,
  },
  computed: {
    ...mapGetters({
      pending_tasks: "reminder/pending_tasks",
      finished_tasks: "reminder/finished_tasks",
      user: "auth/user",
    }),
  },
  data() {
    return {
      all_reminder_search: false,
      dialog: false,
    };
  },
  created() {
    let is_admin = this.user.access_level == 4 ? 1 : 0;
    if (this.finished_tasks.length == 0) {
      this.get_reminders(is_admin);
    }
  },
  methods: {
    ...mapActions({
      get_reminders: "reminder/get_reminders",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
