<template>
  <div>
    <h2>Finished Tasks</h2>
    <v-text-field
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>
    <v-data-table
      :items="finished_tasks"
      :headers="reminder_headers"
      :search="search"
    >
      <template v-slot:item.level_of_urgency="{ item }">
        <v-icon class="mr-2" :color="color_code(item.level_of_urgency)">{{
          item.level_of_urgency == "Basic"
            ? "mdi-calendar"
            : item.level_of_urgency == "Very Urgent!"
            ? "mdi-alert-circle"
            : "mdi-calendar"
        }}</v-icon>
        <span
          :class="
            item.level_of_urgency == 'Basic'
              ? 'primary--text font-weight-bold'
              : item.level_of_urgency == 'Very Urgent!'
              ? 'error--text font-weight-bold'
              : 'warning--text font-weight-bold'
          "
          >{{ item.level_of_urgency }}</span
        >
      </template>
      <template v-slot:item.reminder_type="{ item }">
        <v-icon class="mr-2">
          {{
            item.reminder_type == "Admin Reminder"
              ? "mdi-shield-account"
              : item.reminder_type == "Home Service"
              ? "mdi-home"
              : item.reminder_type == "Back Job"
              ? "mdi-tools"
              : "mdi-pencil"
          }}
        </v-icon>
        <span>{{ item.reminder_type }}</span>
      </template>
      <template v-slot:item.is_finished="{ item }">
        <span v-if="item.is_finished == 1" class="success--text">
          Task Done
        </span>
        <span class="warning--text" v-else> Not yet executed </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      finished_tasks: "reminder/finished_tasks",
      reminders: "reminder/reminders",
      user: "auth/user",
    }),
  },
  methods: {
    change_status(item) {
      let x = window.confirm(
        "Are you sure you want to change the status of this reminder?"
      );
      if (x) {
        item.is_finished = 1;
        console.log(item);
        this.edit_reminder(item)
          .then(() => {
            alert("Task done!");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    del(item) {
      let x = window.confirm("Are you sure you want to delete this reminder?");
      if (x)
        this.delete_reminder(item)
          .then(() => {
            alert("Sucessfully deleted an item");
          })
          .catch((err) => {
            alert(err);
          });
    },
    color_code(level_of_urgency) {
      let x =
        level_of_urgency == "Basic"
          ? "primary"
          : level_of_urgency == "Very Urgent!"
          ? "error"
          : "warning";
      return x;
    },

    ...mapActions({
      edit_reminder: "reminder/edit_reminder",
      delete_reminder: "reminder/delete_reminder",
    }),
  },
  data() {
    return {
      search: "",
      dialog: false,
      reminder_headers: [
        {
          text: "",
          value: "action",
        },
        {
          text: "Level or Urgency",
          value: "level_of_urgency",
        },
        {
          text: "Reminder Type",
          value: "reminder_type",
          width: "180",
        },
        {
          text: "Description",
          value: "reminder_description",
        },

        {
          text: "Reminder Date",
          value: "reminder_date",
        },
        {
          text: "Reminder Time",
          value: "reminder_time",
        },
        {
          text: "Is Finished",
          value: "is_finished",
        },
      ],
    };
  },
  created() {},
};
</script>

<style></style>
